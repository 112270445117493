/***** General *****/
html {
  background: #8e9eab;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to bottom, #eef2f3, #8e9eab);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to bottom, #eef2f3, #8e9eab); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

body {
  min-height: 100VH;
  padding: 20px 75px;
}

h1 {
  font-size: 2.25em;
  margin-bottom: 25px;
}


/***** Header and Nav *****/
.name-container {
  font-weight: bold;
  line-height: normal;
}

nav {
  background-color: inherit !important;
  color: white;
}


/***** Content *****/
section {
  height: calc(100vh - 160px);
}

section div {
  max-height: 100%;
}

.image-container {
  text-align: center;
}

#profile-pic {
  max-height: 100%;
}

img {
  border-radius: 5px;
}

.tech-skills, .soft-skills, .contact-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

li {
  flex-grow: 1;
  text-align: center;;
  display: inline-block;
  background-color: #eef2f3;
  padding: 5px;
  margin: 5px;
  border-radius: 5px;
}

.c1, .c2 {
  padding: 0 5px;
}

figure {
  border: 2px solid black;
  border-radius: 8px;
  margin: 20px 0;
}

figure img {
  z-index: -1;
}

figure h3 {
  color: black !important;
  background-color: white;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  font-weight: bold;
  text-align: center;
  border-top: 2px solid black;
}

figure:hover {
  border: 3px solid black;
  opacity: 80%;
}


/***** Footer *****/
footer {
  text-align: center;
  margin: auto;
}

footer img {
  width: 50px;
  margin: 10px 10px;
}


/***** Media Queries *****/
@media (max-width: 768px) {
  body {
    padding: 20px 40px;
  }
  
  h1 {
    font-size: 1.3em;
  }

  section {
    height: auto;
  }
  
  footer img {
    width: 35px;
    margin: 5px 5px;
  }
}
